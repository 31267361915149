import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"

import { Container, Row, Col } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"

import "bootstrap/dist/css/bootstrap.min.css"
import "@fortawesome/fontawesome-svg-core/styles.css"
import "../styles/extra.css"

export default class Impressum extends React.Component {
  componentDidMount() {
    function addClass() {
      document.querySelector("header").classList.add("scrolled")
    }
    function removeClass() {
      document.querySelector("header").classList.remove("scrolled")
    }
    window.addEventListener("scroll", function() {
      if (window.scrollY > 90) {
        addClass()
      } else {
        removeClass()
      }
    })
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Paul Cyronek — Impressum</title>
          <link rel="canonical" href="https://paulcyronek.de/impressum" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>

        <header></header>
        <main className="my-4">
          <Container>
            <Row>
              <Col>
                <h3>Impressum</h3>
                <p>Angaben gemäß § 5 TMG</p>
                <p>
                  Paul Cyronek <br />
                  Anklamer Str. 20 <br />
                  10115 Berlin, Deutschland
                </p>
                <p>
                  <strong>Vertreten durch:</strong> <br />
                  Paul Cyronek
                </p>
                <p>
                  <strong>Kontakt:</strong> <br />
                  E-Mail:{" "}
                  <a href="mailto:mail@paulcyronek.de">mail@paulcyronek.de</a>
                </p>
                <h4>Haftungsausschluss</h4>
                <p>
                  <strong>Haftung für Inhalte:</strong> <br />
                  Die Inhalte unserer Seiten wurden mit größter Sorgfalt
                  erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität
                  der Inhalte können wir jedoch keine Gewähr übernehmen. Als
                  Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                  Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                  verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                  Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                  gespeicherte fremde Informationen zu überwachen oder nach
                  Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                  hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                  Nutzung von Informationen nach den allgemeinen Gesetzen
                  bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                  jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                  Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                  Rechtsverletzungen werden wir diese Inhalte umgehend
                  entfernen.
                </p>
                <p>
                  <strong>Haftung für Links:</strong> <br />
                  Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                  deren Inhalte wir keinen Einfluss haben. Deshalb können wir
                  für diese fremden Inhalte auch keine Gewähr übernehmen. Für
                  die Inhalte der verlinkten Seiten ist stets der jeweilige
                  Anbieter oder Betreiber der Seiten verantwortlich. Die
                  verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
                  mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren
                  zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                  inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                  konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
                  Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                  Links umgehend entfernen.
                </p>
                <p>
                  <strong>Urheberrecht:</strong> <br />
                  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                  diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                  Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                  Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
                  der schriftlichen Zustimmung des jeweiligen Autors bzw.
                  Erstellers. Downloads und Kopien dieser Seite sind nur für den
                  privaten, nicht kommerziellen Gebrauch gestattet. Soweit die
                  Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
                  werden die Urheberrechte Dritter beachtet. Insbesondere werden
                  Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                  trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                  bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                  von Rechtsverletzungen werden wir derartige Inhalte umgehend
                  entfernen.
                </p>
              </Col>
            </Row>
          </Container>
        </main>
        <footer>
          <Container>
            <Row>
              <Col>
                <div className="text-right py-3">
                  <div className="social-media py-2 mb-1">
                    <a
                      className="mr-1"
                      href="https://facebook.com/paulcyronek"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        mask={faCircle}
                        size="2x"
                        transform="shrink-6"
                      />
                    </a>
                    <a
                      className="ml-2 mr-1"
                      href="https://instagram.com/paulcyronek"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        mask={faCircle}
                        size="2x"
                        transform="shrink-6"
                      />
                    </a>
                    <a
                      className="ml-2"
                      href="https://de.linkedin.com/in/paul-cyronek-4b81a1156"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faLinkedinIn}
                        mask={faCircle}
                        size="2x"
                        transform="shrink-6"
                      />
                    </a>
                  </div>
                  <p className="font-weight-light mb-1">
                    Copyright &copy;{" "}
                    <span id="update-year">{new Date().getFullYear()}</span>,
                    Paul Cyronek
                  </p>
                  <p className="mb-1">
                    <Link className="text-reset" to="/impressum">
                      Impressum
                    </Link>
                    <span className="font-weight-light"> | </span>
                    <Link className="text-reset" to="/datenschutz">
                      Datenschutz
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    )
  }
}
